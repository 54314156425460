import React from "react";
import { FaUserCircle } from "react-icons/fa";
import logo from '../../Assets/mar.png'


import { Navlinks } from "./Navbar";

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  console.log("showMenu", showMenu);
  return (
    <div
      className={`${
        showMenu ? "left-0" : "-left-[100%]"
      } fixed bottom-0 top-0 z-20 flex h-screen w-[75%] flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-16 text-black transition-all duration-200 md:hidden rounded-r-xl shadow-md`}
      >

      {/* <img src={logo} alt="" className='w-20 h-20 lg:w-20 lg:h-20' /> */}

      <div className="card">

        <div className="flex items-center justify-start gap-3">
          <FaUserCircle size={50} />
          <div>
            <h1>Hello User</h1>
            <h1 className="text-sm text-slate-500">Premium user</h1>
          </div>
        </div>
        <nav className="mt-12">
          <ul className="space-y-4 text-sm">
            {Navlinks.map((data) => (
              <li
              onClick={()=>setShowMenu(false)}
              >
                <a href={data.link} className="mb-5 inline-block">
                +  {data.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <div className="footer">
        <h1>
          Made with ❤ For <a href="/">
          You</a>{" "}
        </h1>
      </div>
    </div>
  );
};

export default ResponsiveMenu;
